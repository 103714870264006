import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import { loadAirwallex, redirectToCheckout } from 'airwallex-payment-elements';
import { v4 as uuid } from 'uuid';
import DiscountCoupon from './components/DiscountCoupon';
import closeIcon from '../../assets/images/ic_close.png';
import Header from '../../components/layout/Header';
import check from '../../assets/images/ic_checked.png';
import blackCheck from '../../assets/images/ic_check_default.png';
import date from '../../assets/images/ic_date.png';
import unCheck from '../../assets/images/ic_unchecked.png';
import profile from '../../assets/images/ic_profile_default.png';
import arrow from '../../assets/images/ic_arrow_left.png';
import Box from '../../components/element/Box';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';
import UserService from '../../services/userService';
import MateService from '../../services/mateService';
import billingService from '../../services/billingService';
import { PaymentIntent } from '../../models/paymentIntent';
import styles from './SearchProductPayment.module.css';
import useGlobalStore from '../../stores/globalStore';
import { Coupon } from '../../models/coupon';
import timezoneData from '../../assets/data/timezone.json';
import { format } from 'date-fns';
import dayjs from 'dayjs';

function SearchProductPayment() {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { setTempMeetingRequestData } = useGlobalStore();
  const { id } = location.state; // location.state에서 id를 가져옴
  const [openMyCoupon, setOpenMyCoupon] = useState<boolean>(false);
  const [openNotice, setOpenNotice] = useState<boolean>(false);
  const [openOther, setOpenOther] = useState<boolean>(false);
  const [openRefund, setOpenRefund] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [usedCoupon, setUsedCoupon] = useState<boolean>(false);
  const [selectedCoupon, setSelectedCoupon] = useState<number | null>(null); // 선택된 쿠폰의 인덱스
  const [couponList, setCouponList] = useState<Coupon[]>([]); //유저가 보유하고 있는 쿠폰 목록.
  const mateData = location.state.mateData;
  const meeting_request_info = location.state.meetingRequestInfo;
  const myData = location.state.myData;
  const selectedProductData = location.state.selectedProductData;
  const { user } = useGlobalStore();

  useEffect(() => {
    // 화면 크기 변경 감지
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    // 초기 상태 설정
    handleResize();

    // 리스너 등록
    window.addEventListener('resize', handleResize);

    // 리스너 정리
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (couponList.length > 0) {
      const filteredCoupons = couponList.filter((coupon) => {
        return coupon.type === selectedProductData.type;
      });

      if (filteredCoupons.length > 0) {
        setSelectedCoupon(0); // 필터링된 쿠폰 중 첫 번째 쿠폰을 선택
        setUsedCoupon(true); // 쿠폰 사용 상태를 true로 설정
      }
    }
  }, [couponList, selectedProductData.type]);

  useEffect(() => {
    // 화면 크기 변경 감지
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    // 초기 상태 설정
    handleResize();

    // 리스너 등록
    window.addEventListener('resize', handleResize);

    // 리스너 정리
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  async function loadData() {
    //쿠폰 목록 요청.
    const data = await UserService.couponList('price');
    if (data !== undefined) {
      setCouponList(data);
    }
  }

  const transformTimezone = (tzCode: string): string => {
    if (!tzCode.includes(', ')) return tzCode;
    const parts = tzCode.split(', ');
    return `${parts[1]}/${parts[0]}`;
  };

  const matchedUserTimezone = timezoneData.find((tz) => tz.label === user!.timezone)!;
  const userTimezone = transformTimezone(matchedUserTimezone.tzCode);

  const checkBtn = () => {
    setChecked(!checked);
  };

  const openOtherSheet = () => {
    setOpenNotice(!openNotice);
  };
  const openNoticeSheet = () => {
    if (!checked) {
      toast('error', 'Please Check!', false);
      return;
    }
    setOpenNotice(!openNotice);
  };

  const openMyCouponSheet = () => {
    setOpenMyCoupon((prevState) => !prevState);
  };

  const selectedUseCoupon = (coupon: number) => {
    toast('success', 'Coupon applied.', false);
    setOpenMyCoupon(false);
    setUsedCoupon(true);
    setSelectedCoupon(coupon);
  };
  const handlePayment = async () => {
    const demoMode: boolean = false;

    if(demoMode) {
      //미팅 요청 정보 임시 보관.
      setTempMeetingRequestData({
        mate_user_id: mateData.user_id,
        product_id: selectedProductData.id,
        schedule1: meeting_request_info.schedule1,
        schedule2: meeting_request_info.schedule2,
        schedule3: meeting_request_info.schedule3,
        selected_subjects: meeting_request_info.selected_subjects,
        user_written_subject: meeting_request_info.user_written_subject,
        file_url: meeting_request_info.file_url,
        link_url: meeting_request_info.link_url,
        use_coupon_id: selectedCoupon !== null ? couponList[selectedCoupon].id ?? 0 : 0,
      });
      navigate('/meeting/paymentComplete');
    }else{
      //결제 페이지 이동.
      await loadAirwallex({
        env: 'prod',
      });
      const intent = await billingService.createPaymentIntent(
        finalPrice,
        selectedProductData.type === 'COFFEECHAT'
          ? 'Coffee Chat'
          : selectedProductData.type === 'INTERVIEW'
          ? 'Interview Practice'
          : 'Resume/Portfolio Review',
        selectedProductData.id
      );
      const { id, client_secret, currency } = intent || {};

      try {
        //미팅 요청 정보 임시 보관.
        setTempMeetingRequestData({
          mate_user_id: mateData.user_id,
          product_id: selectedProductData.id,
          schedule1: meeting_request_info.schedule1,
          schedule2: meeting_request_info.schedule2,
          schedule3: meeting_request_info.schedule3,
          selected_subjects: meeting_request_info.selected_subjects,
          user_written_subject: meeting_request_info.user_written_subject,
          file_url: meeting_request_info.file_url,
          link_url: meeting_request_info.link_url,
          use_coupon_id: selectedCoupon !== null ? couponList[selectedCoupon].id ?? 0 : 0,
        });

        redirectToCheckout({
          env: 'prod',
          mode: 'payment',
          currency: currency,
          intent_id: id,
          client_secret: client_secret,
          applePayRequestOptions: {
            countryCode: 'HK',
          },
          googlePayRequestOptions: {
            countryCode: 'HK',
          },
          recurringOptions: {
            card: {
              next_triggered_by: 'customer',
              merchant_trigger_reason: 'scheduled',
              currency: currency,
            },
          },
          theme: {
            popupWidth: 418,
            popupHeight: 549,
          },
          successUrl: `${window.location.origin}/meeting/paymentComplete`,
        });        
      } catch (error) {
        console.error(error);
      }
    }
  };

  const toggleCouponSelect = (index: number) => {
    // 같은 쿠폰을 클릭할 경우 선택 해제
    setSelectedCoupon(index === selectedCoupon ? null : index);
    toast('success', 'Coupon applied.', false);
    setOpenMyCoupon(false);
    setUsedCoupon(true);
  };

  const discountPrice =
    selectedCoupon !== null
      ? selectedProductData.price * (couponList[selectedCoupon].discount_rate / 100)
      : 0;

  // 최종 결제 금액 계산
  const finalPrice = selectedProductData.price - discountPrice;
  return (
    <div
      className={styles['payment-container']}
      style={{ position: 'relative' }}
      id='container'
    >
      <Header
        useBackButton={true}
        title={'Proceed to Payment'}
        onBack={() => navigate(-1)}
      />
      <div className={styles['payment-top']}>
        <img
          src={
            mateData?.profile_image
              ? `${process.env.REACT_APP_STORE_ADDRESS}/${mateData.profile_image}`
              : mateData?.user?.profile_image
              ? `${process.env.REACT_APP_STORE_ADDRESS}/${mateData.user.profile_image}`
              : profile
          }
          alt=''
        />
        {`Buy ${
          selectedProductData.type === 'COFFEECHAT'
            ? 'Coffee Chat'
            : selectedProductData.type === 'INTERVIEW'
            ? 'Interview Practice'
            : 'Resume/Portfolio Review'
        }\r\nfrom ${mateData.user.nickname}`}
      </div>
      <div className={styles['payment-title']}>{'Selected Meeting Schedule'}</div>
      <div className={styles['payment-sub-title']}>{'Mate will confirm the time'}</div>
      <div className={styles['payment-date']}>
        <img
          src={date}
          alt=''
        />
        <p>
          {dayjs(meeting_request_info.schedule1).tz(userTimezone).format('MMM DD (ddd) HH:mm A')}
        </p>
        <p>
          {dayjs(meeting_request_info.schedule2).tz(userTimezone).format('MMM DD (ddd) HH:mm A')}
        </p>
        <p>
          {dayjs(meeting_request_info.schedule3).tz(userTimezone).format('MMM DD (ddd) HH:mm A')}
        </p>
      </div>
      <div className={styles['bold-line']}></div>
      <div className={styles['payment-coupon']}>
        {''}
        <div className={styles['payment-coupon-item']}>
          <div className={styles['payment-title']}>{'Coupon'} </div>
          <span onClick={() => openMyCouponSheet()}>{'Apply a different coupon'}</span>
        </div>
        <div className={styles['payment-coupon-active']}>
          {usedCoupon && selectedCoupon !== null ? (
            <div>
              <img
                src={blackCheck}
                alt=''
              />
              <span>{couponList[selectedCoupon].discount_rate}% discount coupon applied</span>
            </div>
          ) : (
            <div>No coupon applied</div>
          )}
        </div>
      </div>
      <div className={styles['bold-line']}></div>

      <div className={styles['payment-price-title']}>{'Total Payment Amount'}</div>
      <div className={styles['payment-price']}>
        <div className={styles['payment-price-container']}>
          {selectedProductData.type === 'COFFEECHAT'
            ? 'CoffeeChat'
            : selectedProductData.type === 'INTERVIEW'
            ? 'Interview Practice'
            : selectedProductData.type === 'REVIEW'
            ? 'Resume/Portfolio Review'
            : 'None'}{' '}
          {`(30m)`}
          <span>{selectedProductData.price} USD</span>
        </div>
        <div className={styles['payment-price-discount-container']}>
          {usedCoupon && selectedCoupon !== null ? (
            <>
              {'Discount'}
              <span>-{discountPrice} USD</span>
            </>
          ) : (
            <div>No discount applied</div>
          )}
        </div>
        <div className={styles['line']}></div>
        <div className={styles['payment-price-payment-amount-container']}>
          <>
            {'Total Amount'}
            {usedCoupon && selectedCoupon !== null ? (
              <>
                <span>{finalPrice} USD</span>
              </>
            ) : (
              <>
                <span>{selectedProductData.price} USD</span>
              </>
            )}
          </>
        </div>
        <div className={styles['payment-price-container']}>{'(VAT included)'}</div>
      </div>
      <div className={styles['bold-line']}></div>

      <div className={styles['payment-card']}>
        {''}
        <div className={styles['payment-title']}>{'Payment Method'}</div>
        <Box
          text='Credit Card (VISA/MASTER)'
          pay={true}
        />
      </div>
      <div className={styles['bold-line']}></div>

      <div className={styles['payment-notice']}>
        {''}
        <div className={styles['payment-title']}>{'Notes'}</div>
        <ul>
          <li>Over 10 minutes late is considered a no-show.</li>
          <li>For quality assurance, the meeting will be recorded and stored for 7 days.</li>
          <li>If the mate does not accept within 48 hours, it will be automatically canceled</li>
        </ul>

        <div className={styles['payment-notice-more']}>
          <div
            className={styles['payment-notice-more-container']}
            onClick={() => setOpenRefund(true)}
          >
            <div className={styles['payment-title']}>{'Refund Policy'}</div>
            <img
              src={arrow}
              alt=''
            />
          </div>
          <div className={styles['line']}></div>

          <div
            className={styles['payment-notice-more-container']}
            onClick={() => setOpenOther(true)}
          >
            <div className={styles['payment-title']}>{'Cancellation Policy'}</div>
            <img
              src={arrow}
              alt=''
            />
          </div>
        </div>
      </div>
      <div className={styles['bold-line']}></div>
      <div
        className={styles['payment-check-box']}
        onClick={checkBtn}
      >
        <img
          src={checked ? check : unCheck}
          alt=''
        />
        {'Agree and Proceed to Payment'}
      </div>
      <div className={styles['bold-line']}></div>
      <div className={styles['button-container']}>
        <Button
          height={'5.6rem'}
          radius={12}
          activeFontSize='1.6rem'
          activeFontWeight={700}
          onClick={openNoticeSheet}
          active={checked ? true : false}
        >
          Proceed to Payment
        </Button>
      </div>
      <Sheet
        isOpen={openMyCoupon}
        detent='content-height'
        onClose={() => setOpenMyCoupon(false)}
        mountPoint={document.getElementById('container')!}
        style={{
          position: isMobile ? 'fixed' : 'absolute', // 화면 크기에 따라 동적으로 변경
        }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '41.1rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>My Coupons</span>
              <img
                src={closeIcon}
                alt='close Icon'
                onClick={() => setOpenMyCoupon(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              {['COFFEECHAT', 'INTERVIEW', 'REVIEW'].includes(selectedProductData.type) ? (
                <>
                  {couponList.map((coupon: Coupon, idx: number) => (
                    <div key={coupon.id}>
                      {coupon.type === selectedProductData.type && (
                        <DiscountCoupon
                          text={coupon.name}
                          discount={coupon.discount_rate}
                          start={coupon.created_date}
                          end={coupon.end_date}
                          useCheck={true}
                          useCoupon={false}
                          onClick={() => toggleCouponSelect(idx)}
                          active={false}
                          padding={0}
                          checked={selectedCoupon === idx}
                        />
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <div>You don't have any coupons.</div>
              )}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenMyCoupon(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
      <Sheet
        isOpen={openNotice}
        detent='content-height'
        onClose={() => setOpenNotice(false)}
        mountPoint={document.getElementById('container')!}
        style={{
          position: isMobile ? 'fixed' : 'absolute', // 화면 크기에 따라 동적으로 변경
        }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '48rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Cancellation Policy</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenNotice(false)}
              />
            </div>

            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <p>
                Cancellations must be made at least 3 hours before the confirmed time. If you wish
                to change your appointment, please cancel the current one and schedule a new
                meeting.
              </p>
              <p>
                No-Show: If you do not attend the scheduled time or cancel the reservation without
                prior notice, it will be treated as a no-show.
              </p>

              <div className={styles['sheet-terms-container']}>
                <span>Refund Policy</span>
              </div>
              <p>
                Refunds are available for cancellations made at least 3 hours before the scheduled
                time.
              </p>
              <p>
                Cancellations made less than 3 hours before the schedule or failure to attend will
                not be refunded. However, if there is an issue due to a service error, please
                contact our customer support team.
              </p>
              <div className={styles['sheet-button']}>
                <Button
                  width='unset'
                  marginHorizontalRem={1.6}
                  active={true}
                  onClick={handlePayment}
                >
                  I Agree
                </Button>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenNotice(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>

      <Sheet
        isOpen={openOther}
        detent='content-height'
        onClose={() => setOpenOther(false)}
        mountPoint={document.getElementById('container')!}
        style={{
          position: isMobile ? 'fixed' : 'absolute', // 화면 크기에 따라 동적으로 변경
        }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '24rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Cancellation Policy</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenOther(false)}
              />
            </div>

            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <p>
                Cancellations must be made at least 3 hours before the confirmed time. If you wish
                to change your appointment, please cancel the current one and schedule a new
                meeting.
              </p>
              <p>
                No-Show: If you do not attend the scheduled time or cancel the reservation without
                prior notice, it will be treated as a no-show.
              </p>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenOther(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>

      <Sheet
        isOpen={openRefund}
        detent='content-height'
        onClose={() => setOpenRefund(false)}
        mountPoint={document.getElementById('container')!}
        style={{
          position: isMobile ? 'fixed' : 'absolute', // 화면 크기에 따라 동적으로 변경
        }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '24rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Refund Policy</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenRefund(false)}
              />
            </div>

            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <p>
                Refunds are available for cancellations made at least 3 hours before the scheduled
                time.
              </p>
              <p>
                Cancellations made less than 3 hours before the schedule or failure to attend will
                not be refunded. However, if there is an issue due to a service error, please
                contact our customer support team.
              </p>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenRefund(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
    </div>
  );
}

export default SearchProductPayment;
