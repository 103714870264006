import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.css';
import useGlobalStore from '../../stores/globalStore';
import useToast from '../../hook/useToast';
import '../../styles/fonts/Font.css';

import homeOffIcon from '../../assets/images/nav/ic_nav_home_off.png';
import homeOnIcon from '../../assets/images/nav/ic_nav_home_on.png';
import myMeetingOffIcon from '../../assets/images/nav/ic_nav_my_meeting_off.png';
import myMeetingOnIcon from '../../assets/images/nav/ic_nav_my_meeting_on.png';
import menuOffIcon from '../../assets/images/nav/ic_nav_menu_off.png';
import menuOnIcon from '../../assets/images/nav/ic_nav_menu_on.png';
import communityOffIcon from '../../assets/images/nav/ic_nav_community_off.png';
import communityOnIcon from '../../assets/images/nav/ic_nav_community_on.png';
import settingOffIcon from '../../assets/images/nav/ic_nav_setting_off.png';
import settingOnIcon from '../../assets/images/nav/ic_nav_setting_on.png';

function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const { selectedBottomMenu, setSelectedBottomMenu, isAuthenticated } = useGlobalStore();

  useEffect(() => {
    if(location.pathname === '/') {
      setSelectedBottomMenu('home');
    }else if(location.pathname === '/meeting/myMeeting') {
      setSelectedBottomMenu('meeting');
    }else if(location.pathname === '/menu') {
      setSelectedBottomMenu('menu');
    }else if(location.pathname === '/community') {
      setSelectedBottomMenu('community');
    }else if(location.pathname === '/setting') {
      setSelectedBottomMenu('setting');
    }
  }, [location]);

  function goMenu(name: string) {
    setSelectedBottomMenu(name);

    if(name === 'home') {
      navigate("/");
    }else if(name === 'meeting') {
      if(!isAuthenticated) { //로그인되지 않은 경우.
        toast('error', 'You are not logged in.');
        return;
      }
      
      navigate("/meeting/myMeeting");
    }else if(name === 'menu') {
      if(!isAuthenticated) { //로그인되지 않은 경우.
        toast('error', 'You are not logged in.');
        return;
      }

      navigate("/menu");
    }else if(name === 'community') {
      window.open('https://naileditapp.notion.site/About-Us-15150a7d0c9280fb96f7c17e045d5480', "_blank", "noopener, noreferrer");;
    }else if(name === 'setting') {
      if(!isAuthenticated) { //로그인되지 않은 경우.
        toast('error', 'You are not logged in.');
        return;
      }

      navigate("/setting");
    }
  }

  return (
    <nav className={styles.footer}>
      <div className={styles['nav-container']}>
        <div className={`${styles['nav-item']} ${selectedBottomMenu == 'home' ? styles['active-nav-item'] : ''}`} onClick={() => goMenu('home')}>
          <img src={selectedBottomMenu == 'home' ? homeOnIcon : homeOffIcon} alt=""/>
          {t('nav_bar_home')}
        </div>
        <div className={`${styles['nav-item']} ${selectedBottomMenu == 'meeting' ? styles['active-nav-item'] : ''}`} onClick={() => goMenu('meeting')}>
          <img src={selectedBottomMenu == 'meeting' ? myMeetingOnIcon : myMeetingOffIcon} alt=""/>
          {t('nav_bar_meeting')}
        </div>
        <div className={`${styles['nav-item']} ${selectedBottomMenu == 'menu' ? styles['active-nav-item'] : ''}`} onClick={() => goMenu('menu')}>
          <img src={selectedBottomMenu == 'menu' ? menuOnIcon : menuOffIcon} alt=""/>
          {t('nav_bar_menu')}
        </div>
        <div className={`${styles['nav-item']} ${selectedBottomMenu == 'community' ? styles['active-nav-item'] : ''}`} onClick={() => goMenu('community')}>
          <img src={selectedBottomMenu == 'community' ? communityOnIcon : communityOffIcon} alt=""/>
          {t('nav_bar_community')}
        </div>
        <div className={`${styles['nav-item']} ${selectedBottomMenu == 'setting' ? styles['active-nav-item'] : ''}`} onClick={() => goMenu('setting')}>
          <img src={selectedBottomMenu == 'setting' ? settingOnIcon : settingOffIcon} alt=""/>
          {t('nav_bar_setting')}
        </div>
      </div>
    </nav>
  );
}

export default Footer;
