import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MeetingConfirmDetail.module.css';
import Header from '../../components/layout/Header';
import Box from '../../components/element/Box';
import profileIcon from '../../assets/images/ic_profile_default.png';
import line from '../../assets/images/ic_line.png';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';
import { useLocation } from 'react-router-dom';
import { addMinutes, format } from 'date-fns';
import { Meeting } from '../../models/meeting';
import meetingService from '../../services/meetingService';
import useGlobalStore from '../../stores/globalStore';
import timezoneData from '../../assets/data/timezone.json';
import dayjs from 'dayjs';
import checkImg from '../../assets/images/ic_check_default.png';

function MeetingConfirmDetail() {
  const { user } = useGlobalStore();
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const meetingData = location.state; // 전달된 state 데이터를 받음
  const meetingId = location.state.meetingId; // 전달된 state 데이터를 받음
  const [meetingTargetData, setMeetingTargetData] = useState<Meeting | null>(null);
  const newDate = addMinutes(
    new Date(meetingData?.meeting_date || meetingTargetData?.meeting_date || Date.now()),
    meetingData?.product?.time || meetingTargetData?.product?.time || 0
  );
  const formattedDate = format(newDate, 'MM/dd(EEE) HH:mm'); // 'HH:mm'으로 분을 표시
  useEffect(() => {
    if (meetingId) {
      loadTargetMeeting(meetingId);
    }
  }, []);

  async function loadTargetMeeting(id: number) {
    const data = await meetingService.getMeetingById(id);
    if (data) {
      setMeetingTargetData(data);
    } else {
      console.log('에러');
    }
  }

  const transformTimezone = (tzCode: string): string => {
    if (!tzCode.includes(', ')) return tzCode;
    const parts = tzCode.split(', ');
    return `${parts[1]}/${parts[0]}`;
  };

  const matchedUserTimezone = timezoneData.find((tz) => tz.label === user?.timezone)!;
  const transUserTimezone = transformTimezone(matchedUserTimezone.tzCode);

  const downloadBtn = () => {
    // 파일 URL 확인
    const fileUrl =
      meetingData?.meeting_request_info?.file_url ||
      meetingTargetData?.meeting_request_info?.file_url;

    if (!fileUrl) {
      toast('error', 'No Attached Link', false);
      return;
    }

    // 가상의 a 태그 생성 후 클릭 이벤트로 다운로드
    const link = document.createElement('a');
    link.href = fileUrl;

    link.click();

    // 다운로드 성공 알림
    toast('success', 'File Downloaded', false);
  };

  const copyToClipboard = (text: string) => {
    if (!text.trim()) {
      toast('error', 'No attached Link', false);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => toast('success', 'The meeting link has been copied', false),
      () => toast('error', 'Failed to copy meeting link', false)
    );
  };

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />

      <div className={styles['confirm-detail-title']}>
        {'Your '}
        <span>{meetingData?.product?.type || meetingTargetData?.product?.type || 'undefined'}</span>

        {' with'}
        <br />
        {`${
          meetingData?.mate?.user?.nickname ||
          meetingTargetData?.mate?.user?.nickname ||
          'undefinded'
        } planner is about to start.`}
      </div>

      <div className={styles['confirm-detail-sub-title']}>{'Scheduled Time'}</div>

      <Box
        text={` ${dayjs(meetingData?.meeting_date || meetingTargetData?.meeting_date)
          .tz(transUserTimezone)
          .format('MMM DD (ddd) HH:mm A')} ~ ${dayjs(
          meetingData?.meeting_date || meetingTargetData?.meeting_date
        )
          .tz(transUserTimezone)
          .add(meetingData.product.time || meetingTargetData?.product.time, 'minute')
          .format('HH:mm A')}
        `}
      />

      <div className={styles['confirm-detail-text']}>
        {'(*) You can join 10 minutes before the meeting starts.'}
      </div>

      <div className={styles['confirm-detail-member-container']}>
        <div className={styles['confirm-detail-sub-title']}>{'Participants'}</div>

        <div className={styles['confirm-detail-member-items']}>
          <div className={styles['confirm-detail-profile']}>
            <img
              src={
                meetingData?.user?.profile_image
                  ? `${process.env.REACT_APP_STORE_ADDRESS}/${meetingData?.user.profile_image}`
                  : meetingTargetData?.user?.profile_image
                  ? `${process.env.REACT_APP_STORE_ADDRESS}/${meetingTargetData?.user?.profile_image}`
                  : profileIcon
              }
              alt='User Profile'
            />
            <p>{meetingData?.user?.nickname || meetingTargetData?.user?.nickname}</p>
          </div>

          <img
            className={styles['confirm-detail-line']}
            src={line}
            alt='Line'
          />

          <div className={styles['confirm-detail-profile']}>
            <img
              src={
                meetingData?.mate?.profile_image
                  ? `${process.env.REACT_APP_STORE_ADDRESS}/${meetingData?.mate.profile_image}`
                  : meetingTargetData?.mate?.profile_image
                  ? `${process.env.REACT_APP_STORE_ADDRESS}/${meetingTargetData?.mate?.profile_image}`
                  : meetingData?.mate?.user.profile_image
                  ? `${process.env.REACT_APP_STORE_ADDRESS}/${meetingData?.mate.user.profile_image}`
                  : meetingTargetData?.mate?.user?.profile_image
                  ? `${process.env.REACT_APP_STORE_ADDRESS}/${meetingTargetData?.mate?.user.profile_image}`
                  : profileIcon
              }
              alt='Mate Profile'
            />
            <p>
              <span>
                {meetingData?.mate?.user?.nickname ||
                  meetingTargetData?.mate?.user?.nickname ||
                  'Unknown'}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={styles['confirm-detail-meet-container']}>
        <div className={styles['confirm-detail-sub-title']}>{'Suggested Topics'}</div>
          {meetingData?.meeting_request_info?.selected_subjects.map((subject: string, index: number) => (
            <div
              className={styles['confirm-detail-meet-box']}
              key={index}
            >
              <img
                src={checkImg}
                alt=''
              />
              <span>{subject}</span>
            </div>
          ))}
      </div>
      <div className={styles['confirm-detail-file-container']}>
        <div className={styles['confirm-detail-sub-title']}>{'Resume / Portfolio / Link'}</div>
        <Box
          text={
            meetingData.meeting_request_info?.file_url
              ? `${meetingData?.meeting_request_info?.file_url.slice(0, 30)}...`
              : meetingTargetData?.meeting_request_info?.file_url
              ? `${meetingTargetData?.meeting_request_info?.file_url.slice(0, 30)}...`
              : 'No Attached File'
          }
          buttonTitle='Download'
          onButtonClick={downloadBtn}
          active={
            !!meetingData?.meeting_request_info?.file_url ||
            !!meetingTargetData?.meeting_request_info?.file_url
          } // URL이 존재하면 활성화, 없으면 비활성화
        />

        <Box
          text={
            meetingData.meeting_request_info?.link_url
              ? `${meetingData?.meeting_request_info?.link_url.slice(0, 30)}...`
              : meetingTargetData?.meeting_request_info?.link_url
              ? `${meetingTargetData?.meeting_request_info?.link_url.slice(0, 30)}...`
              : 'No Attached Link'
          }
          buttonTitle='Copy Link'
          onButtonClick={() =>
            copyToClipboard(
              meetingData?.meeting_request_info?.link_url ||
                meetingTargetData?.meeting_request_info?.link_url ||
                ''
            )
          }
          active={
            !!meetingData?.meeting_request_info?.link_url ||
            !!meetingTargetData?.meeting_request_info?.link_url
          } // URL이 존재하면 활성화, 없으면 비활성화
        />
      </div>
      <div className={styles['confirm-detail-warning-container']}>
        <div className={styles['confirm-detail-sub-title']}>
          {'Guidelines for a positive meeting:'}
        </div>
        <Box
          text={'Keep personal information private'}
          readOnly={true}
        />
        <Box
          text={'Please join within 10 minutes. No refunds for late arrivals'}
          readOnly={true}
        />
        <Box
          text={'Be respectful and professional'}
          readOnly={true}
        />
      </div>
    </div>
  );
}

export default MeetingConfirmDetail;
